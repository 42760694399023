import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'

const RadiolucentCTA = () => (
  <div className="radiolucentCTA">
    <Container>
        <Row>
            <Col>
                <h2>For more information or to request a product evaluation, contact your LifeSync representative or <Link to="/contact/">Customer Service online</Link> or by telephone, 1.800.358.2468.</h2>
            </Col>
        </Row>
    </Container>
  </div>
)

export default RadiolucentCTA
