import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const RadiolucentHeadingCopy = () => (
  <div className="radiolucentHeadingCopy">
    <Container>
        <Row>
            <Col>
              <h2>Reliable solutions from radiolucent experts</h2>
              <p>30+ years experience in making radiolucent ECG products—including the first and only shielded reusable radiolucent leadwire—means LifeSync is a trusted partner in providing cardiac monitoring and diagnostic solutions. LifeSync supplies cables, leadwires, and accessories to the world’s top cardiac monitoring and diagnostic equipment companies, hospitals, and clinics.</p>
          </Col>
        </Row>
    </Container>
  </div>
)

export default RadiolucentHeadingCopy
