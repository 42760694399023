import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'


import spuOne from '../../images/disposable-1.jpg'
import spuTwo from '../../images/disposable-2.jpg'
import spuThree from '../../images/disposable-3.jpg'
import spuFour from '../../images/disposable-4.jpg'
import spuFive from '../../images/disposable-5.jpg'

const RadiolucentHeaderFiveImages = () => (
  <div className="radiolucentHeaderFiveImages">
    <Container>
        <Row>
            <Col>
              <h2>Single-patient-use radiolucent LeadWear<sup>&reg;</sup></h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="leadwire-items">
              <img src={spuOne} alt="Leadwire 1"/>
              <img src={spuTwo} alt="Leadwire 2"/>
              <img src={spuThree} alt="Leadwire 3"/>
              <img src={spuFour} alt="Leadwire 4"/>
              <img src={spuFive} alt="Leadwire 5"/>
            </div>
          </Col>
        </Row>
    </Container>
  </div>
)

export default RadiolucentHeaderFiveImages
