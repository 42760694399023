import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ConnectorsImg from '../../images/radiolucent-leadwire-connectors.png'
import ConnectorImg from '../../images/radiolucent-leadwire-connector.png'
import SpecsImg from '../../images/radiolucent-leadwire-specs.png'

import ConnectorsImgMobile from '../../images/radiolucent-leadwire-connectors-mobile.png'
import ConnectorImgMobile from '../../images/radiolucent-leadwire-connector-mobile.png'
import DetailsImg from '../../images/radiolucent-leadwire-details.png'

import leadwireOne from '../../images/reusable-1.png'
import leadwireTwo from '../../images/reusable-2.png'
import leadwireThree from '../../images/reusable-3.png'
import leadwireFour from '../../images/reusable-4.png'
import leadwireFive from '../../images/reusable-5.png'

const RadiolucentSpecs = () => (
  <div className="radiolucentSpecs">
    <Container fluid>
      <Row>
        <Col md={4}>
          <img src={ConnectorsImg} alt="LifeSync Radoilucent Leadwire Connectors" className="leftProductImg"  />
          <img src={ConnectorImgMobile} alt="LifeSync Radiolucent Leadwire Connector" width="160" className="mobileImg" />
        </Col>
        <Col md={5}>
          <div className="caption">
            <h2>Ease, efficiency, unrivaled selection</h2>
            <p>Single-use or disposable. Shielded or unshielded. More than 60 radiolucent configurations all from a single source. Radiolucency eliminates the need to replace or reposition ECG leads during fluoroscopy, saving procedure time. Our high-quality solutions enhance productivity, enable standardization, and support outstanding, cost-effective patient care.</p>
            <p className="detailsWrap"><img src={SpecsImg} alt="LifeSync Radiolucent Leadwire Specs" width="650" className="detailsImg" /></p>
            <p className="mobileConnectorsWrap"><img src={ConnectorsImgMobile} alt="LifeSync Radiolucent Leadwire Connectors" width="300" className="connectorImg" /></p>
            <p className="mobileDetailsWrap"><img src={DetailsImg} alt="LifeSync Radiolucent Leadwire Details" width="250" className="detailsImg" /></p>
          </div>
        </Col>
        <Col md={3}>
          <img src={ConnectorImg} alt="LifeSync Radiolucent Leadwire Connector" className="rightProductImg" />
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col className="caption">
          <br />
          <h2>Reusable radiolucent leadwires</h2>
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="leadwire-items">
            <img src={leadwireOne} alt="Leadwire 1"/>
            <img src={leadwireTwo} alt="Leadwire 2"/>
            <img src={leadwireThree} alt="Leadwire 3"/>
            <img src={leadwireFour} alt="Leadwire 4"/>
            <img src={leadwireFive} alt="Leadwire 5"/>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default RadiolucentSpecs
