import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CaptionStandardization = () => (
  <Container fluid>
        <Row>
            <Col>
                <div className="captionRadiolucent">
                    <h1>Radiolucent ECG Leads</h1>
                    <p>Clear signal, unobstructed views, superior selection, and support.</p>
                </div>
            </Col>
        </Row>
  </Container>
)

export default CaptionStandardization
