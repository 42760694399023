import React from "react"
import { Parallax } from 'react-parallax'

import MainNavBar from "../mainNavBar"
import CaptionRadiolucent from "./captionRadiolucent"

const HeaderRadiolucent = ({ siteTitleContact }) => (
  <Parallax
      bgImage={require('../../images/hero-radiolucent-leadwires-p.jpg')}
      bgImageAlt="LifeSync Radiolucent Leadwires"
      strength="100"
      className="radioLucentParallax"
  >
    <header className="headerRadiolucent">
      <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
      <MainNavBar />
      <CaptionRadiolucent />
    </header>
  </Parallax>
)

export default HeaderRadiolucent
