import React from "react"

import LayoutRadiolucent from "../components/Radiolucent/layoutRadiolucent"
import SEO from "../components/seo"

import RadiolucentTwoColCopyRight from '../components/Radiolucent/radiolucentTwoColCopyRight'
import RadiolucentHeaderFiveImages from '../components/Radiolucent/radiolucentHeaderFiveImages'
import RadiolucentIntro from '../components/Radiolucent/radiolucentIntro'
import RadiolucentSpecs from '../components/Radiolucent/radiolucentSpecs'
import RadiolucentHeadingCopy from '../components/Radiolucent/radiolucentHeadingCopy'
import RadiolucentHeadingCopyTwo from '../components/Radiolucent/radiolucentHeadingCopyTwo'
import RadiolucentCTA from '../components/Radiolucent/radiolucentCTA'

const RadiolucentLeadwires = () => (
  <LayoutRadiolucent>
    <SEO 
      title="LifeSync Radiolucent Leadwear&reg; and Leadwires | LifeSync" 
      description="Get unobstructed views and uncompromised signal and trace quality with LifeSync LeadWear(R) and radiolucent leadwires. "
      ogimg="https://www.lifesync.com/static/hero-home-p-68887cab881dc915bb49debf1d67f892.jpg"
      link="https://www.lifesync.com/radiolucent-leadwires/"
    />
    <RadiolucentIntro />
    <RadiolucentHeadingCopy />
    <RadiolucentSpecs />
    <RadiolucentHeadingCopyTwo />
    <RadiolucentTwoColCopyRight />
    <RadiolucentHeaderFiveImages />
    <RadiolucentCTA />
  </LayoutRadiolucent>
)

export default RadiolucentLeadwires
