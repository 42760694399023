import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'
import IntroImg from '../../images/lifesync-radiolucent-leadwires.jpg'

const RadiolucentIntro = () => (
  <div className="radiolucentIntro">
    <Container>
        <Row>
          <Col md={{span: 7, order: 2}}>
              <div className="caption">
                <h2>Our advantages are clear</h2>
                <p>LifeSync—a global leader in radiolucent leadwire design and manufacturing—offers a broad portfolio of radiolucent products. Expect unobstructed views, and uncompromised signal and trace quality for fast, accurate readings.<br />
                <span span className="disclaimer">*The images shown are for illustration purposes only.</span></p>
              </div>
          </Col>
          <Col md={{span: 5, order: 1}} className="text-center">
            <img src={IntroImg} alt="LifeSync Radiolucent Leadwires Illustration" className="resp-img" />
          </Col>
        </Row>
    </Container>
  </div>
)

export default RadiolucentIntro
