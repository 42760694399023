import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const RadiolucentTwoColCopyRight = () => (
  <div className="radiolucentTwoColCopyRight">
    <Container fluid="true">
        <Row>
            <Col md={6} className="twoColCopyRightCopy">
              <div className="copyRightWrap">
                  <div>&nbsp;</div>
                  <h2>Our innovative 360<sup>&deg;</sup> connector surrounds the electrode for less noise and fewer nuisance alarms.</h2>
              </div>
          </Col>
          <Col md={6} className="twoColCopyLeftImage">
                &nbsp;
            </Col>
        </Row>
    </Container>
  </div>
)

export default RadiolucentTwoColCopyRight
