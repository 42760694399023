import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const RadiolucentHeadingCopyTwo = () => (
  <div className="radiolucentHeadingCopy">
    <Container>
        <Row>
            <Col>
              <h2>Always innovating—LifeSync LeadWear® and more</h2>
              <p>Flexible, lightweight, single-patient-use radiolucent LifeSync LeadWear® can travel with the patient outside of procedural areas. Product details like our innovative 360° connector further support fast, accurate results through excellent visibility, grab, conductivity, and signal. We design all of our radiolucent leads with optimal Cath and EP lab use in mind.</p>
          </Col>
        </Row>
    </Container>
  </div>
)

export default RadiolucentHeadingCopyTwo
